import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Snackbar, Tooltip } from '@mui/material';

import { ILink, PaymentStatus, PaymentType } from '../../../../services/commonTypes';
import { IPaymentMethod } from '../../../../services/getPaymentService';

import Card from './PaymensMethods/Card/Card';
import Sdd from './PaymensMethods/Sdd/Sdd';

import './PaymentMethodSwitcher.scss';

const PaymentMethodSwitcher = (props: IProps) => {
  const { t: translate } = useTranslation();

  const getErrorMessage = (): string => {
    switch (props.paymentStatus) {
      case PaymentStatus.AuthenticationFailed:
        if (props.selectedPaymentMethod === PaymentType.Sdd) {
          return translate('errors.sdd_payment_authentication_failed');
        } else {
          return translate('errors.payment_authentication_failed');
        }
      case PaymentStatus.Declined:
        return translate('errors.payment_declined');
      default:
        return '';
    }
  };

  const initialState: IState = {
    errorMessage: getErrorMessage(),
    isLoading: false,
    isToastErrorDisplayed: false,
    selectedPaymentMethod: props.selectedPaymentMethod || PaymentType.Card,
  };
  const [state, setState] = useState<IState>(initialState);

  const renderPaymentMethod = (paymentType: PaymentType): JSX.Element => {
    switch (paymentType) {
      case PaymentType.Card:
        return (
          <Card
            resourceToken={props.resourceToken}
            errorMessage={state.errorMessage}
            authorizeLink={props.authorizeLink}
            onLoadingChange={handleLoadingChange}
            onUnhandledError={(errorMessage) => handleToastDisplay(true, errorMessage)}
            paymentId={props.paymentId}
            tokenizationMerchantId={props.tokenizationMerchantId}
          />
        );
      case PaymentType.Sdd:
        return (
          <Sdd
            resourceToken={props.resourceToken}
            errorMessage={state.errorMessage}
            authorizeLink={props.authorizeLink}
            onLoadingChange={handleLoadingChange}
            onUnhandledError={(errorMessage) => handleToastDisplay(true, errorMessage)}
          />
        );
      default:
        throw new Error('Payment method not supported');
    }
  };

  const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod): void => {
    if (state.isLoading) {
      return;
    }

    setState((prev) => ({
      ...prev,
      errorMessage: undefined,
      selectedPaymentMethod: paymentMethod.type,
    }));
  };

  const handleToastDisplay = (isDisplayed: boolean, errorMessage?: string) => {
    if (isDisplayed && !errorMessage) {
      return;
    }

    // It keeps the previous error message if the new one is not provided, just to avoid flicky visual effect when hiding the toast.
    const toastErrorMessage = errorMessage ? errorMessage : state.toastErrorMessage;

    setState((prev) => ({
      ...prev,
      isToastErrorDisplayed: isDisplayed,
      toastErrorMessage: toastErrorMessage,
    }));
  };

  const handleLoadingChange = (isLoading: boolean): void =>
    setState((prev) => ({ ...prev, isLoading: isLoading }));

  return (
    <div className="payment-method-switcher-container">
      <div className="payment-method-switcher-label">{translate('payment_method')}</div>

      <div className="payment-method-switcher-selector">
        {props.paymentMethods.map((paymentMethod, i) => (
          <Tooltip
            title={paymentMethod.name}
            key={`payment-method-${i}`}
            arrow
            disableHoverListener={state.isLoading}
          >
            <div
              id={`paymentMethod-${paymentMethod.type}`}
              className={`payment-method-switcher-selector-item ${
                paymentMethod.type === state.selectedPaymentMethod ? 'selected' : ''
              } ${state.isLoading ? 'disabled' : ''}`}
              onClick={() => handleSelectPaymentMethod(paymentMethod)}
            >
              <img src={paymentMethod.iconUrl} alt={paymentMethod.name} />
            </div>
          </Tooltip>
        ))}
      </div>

      {state.selectedPaymentMethod && renderPaymentMethod(state.selectedPaymentMethod)}

      <Snackbar
        open={state.isToastErrorDisplayed}
        autoHideDuration={5000}
        onClose={() => handleToastDisplay(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Alert onClose={() => handleToastDisplay(false)} severity="error" sx={{ width: '350px' }}>
          {state.toastErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PaymentMethodSwitcher;

interface IState {
  selectedPaymentMethod: PaymentType;
  isToastErrorDisplayed: boolean;
  toastErrorMessage?: string;
  errorMessage?: string;
  isLoading: boolean;
}

interface IProps {
  paymentId: string;
  paymentStatus: PaymentStatus;
  resourceToken: string;
  authorizeLink: ILink;
  tokenizationMerchantId: string;
  paymentMethods: IPaymentMethod[];
  selectedPaymentMethod?: PaymentType;
}
