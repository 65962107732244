// Enums

export enum LinkRelation {
  Start = 'hpp:start',
  Cancel = 'hpp:cancel',
  Authorize = 'hpp:authorize',
  Authenticate = 'hpp:authenticate',
  Complete = 'hpp:complete',
  Mandate = 'hpp:mandate',
}

export enum PaymentStatus {
  Initiated = 'initiated',
  Started = 'started',
  Authenticating = 'authenticating',
  AuthenticationFailed = 'authentication_failed',
  Authenticated = 'authenticated',
  Authorized = 'authorized',
  Declined = 'declined',
  IssuingSddMandate = 'issuing_sdd_mandate',
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export enum PaymentType {
  Card = 'card',
  Sdd = 'sepa_direct_debit',
}

export enum BankAccountType {
  Consumer = 'consumer',
  Business = 'business',
}

export enum RegistrationNumberCode {
  Vat = 'Vat',
  DeHrb = 'DeHrb',
  DeHra = 'DeHra',
  DeVr = 'DeVr',
  SiTin = 'SiTin',
  EeKmkr = 'EeKmkr',
}

export enum SddMandateField {
  FirstName = 'first_name',
  LastName = 'last_name',
  PostalAddress = 'postal_address',
  CompanyName = 'company_name',
  RegistrationNumberCode = 'registration_number_code',
  RegistrationNumber = 'registration_number',
  BirthDate = 'birth_date',
  BirthPlace = 'birth_place',
}

// Types

export interface IPaymentResultDto {
  status: PaymentStatus;
  workflow: IWorkflow;
  _links: ILinks;
}

export interface IPaymentResult {
  status: PaymentStatus;
  workflow: IWorkflow;
  links: ILinks;
}

export interface IWorkflow {
  completed: boolean;
  previous?: string;
  next?: string;
}

export interface ILinks {
  self: ILink;
  [key: string]: ILink;
}

export interface ILink {
  href: string;
  methods: HttpMethod[];
}

export interface IItem {
  value: string;
  description: string;
}

export interface IInvalidParametersError {
  title: string;
  errors: ErrorRow;
}

interface ErrorRow {
  [key: string]: string[];
}
