import { useEffect, useRef, useState } from 'react';
import cardValidator from 'card-validator';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Alert, Button, CircularProgress, TextField } from '@mui/material';
import { Stack } from '@mui/system';

import { translateBackendErrors } from '../../../../../../helpers/paymentHelper';
import {
  authorizePayment,
  SupportedBackendErrors,
} from '../../../../../../services/authorizePaymentService';
import { PaymentStatus, PaymentType } from '../../../../../../services/commonTypes';
import { requestToken } from '../../../../../../services/requestTokenService';
import { IPaymentData, tokenizeCard } from '../../../../../../services/tokenizeCardService';
import CardField from '../../../../../shared/CardField/CardField';
import { IPaymentMethodPropsBase } from '../PaymentMethodsTypes';

import './Card.scss';
import '../PaymentMethods.scss';

const Card = (props: IProps) => {
  const { t: translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const useSimulator = searchParams.get('use_simulator')?.toLowerCase() === 'true';

  const [errors, setErrors] = useState<IErrors>({});
  const [errorMessage, setErrorMessage] = useState(props?.errorMessage);
  const [isLoading, setIsLoading] = useState(false);

  const [cvv, setCvv] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const cardholderNameRef = useRef<HTMLInputElement>(null);
  const cardNumberRef = useRef<HTMLInputElement>(null);

  const handleExpiryDateChange = (value: string) => {
    const inputVal = value.replace(/ /g, ''); // Remove all the empty spaces in the input
    let inputNumbersOnly = inputVal.replace(/\D/g, ''); // Get only digits

    if (inputNumbersOnly.length > 4) {
      // If entered value has a length greater than 4 then take only the first 4 digits
      inputNumbersOnly = inputNumbersOnly.substring(0, 4);
    }

    // Get nd array of 2 digits per an element EX: ["12", "06"]
    const splits = inputNumbersOnly.match(/.{1,2}/g);

    setExpiryDate(splits ? splits.join(' / ') : '');
  };

  const handleCvvChange = (value: string) => {
    const regex = /^[0-9\b]+$/;

    if (regex.test(value) || value === '') {
      setCvv(value);
    }
  };

  const isFormValid = (): boolean => {
    const validationErrors: IErrors = {};

    const cardNumberValidationResult = cardValidator.number(cardNumberRef.current?.value);
    if (!cardNumberRef.current?.value) {
      validationErrors.cardNumber = translate('validation_errors.required');
    } else if (!cardNumberValidationResult.isValid) {
      validationErrors.cardNumber = translate('validation_errors.invalid_card_number');
    }

    const cardholderNameMinLength = 5;
    const cardholderNameMaxLength = 50;
    if (!cardholderNameRef.current?.value) {
      validationErrors.cardholderName = translate('validation_errors.required');
    } else if (cardholderNameRef.current?.value.length < cardholderNameMinLength) {
      validationErrors.cardholderName = translate('validation_errors.min_length_unreached', {
        min: cardholderNameMinLength,
      });
    } else if (cardholderNameRef.current.value.length > cardholderNameMaxLength) {
      validationErrors.cardholderName = translate('validation_errors.max_length_reached', {
        max: cardholderNameMaxLength,
      });
    }

    const expiryDateValidationResult = cardValidator.expirationDate(expiryDate);
    if (!expiryDate) {
      validationErrors.expiryDate = translate('validation_errors.required');
    } else if (!expiryDateValidationResult.isValid) {
      validationErrors.expiryDate = translate('validation_errors.invalid_expiry_date');
    }

    const cvvMinLength = 3;
    if (!cvv) {
      validationErrors.cvv = translate('validation_errors.required');
    } else if (cvv.length < cvvMinLength) {
      validationErrors.cvv = translate('validation_errors.min_length_unreached', {
        min: cvvMinLength,
      });
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setErrorMessage(undefined);
    setIsLoading(true);

    try {
      const paymentData: IPaymentData = {
        cardNumber: (cardNumberRef.current?.value as string).replaceAll(' ', ''),
        cardholderName: cardholderNameRef.current?.value as string,
        cvv: cvv as string,
        expiryMonth: expiryDate.substring(0, 2),
        expiryYear: expiryDate.substring(5),
        paymentId: props.paymentId,
      };
      const tokenizeCardResult = await tokenizeCard(
        paymentData,
        props.tokenizationMerchantId,
        useSimulator,
      );

      const tokenResponse = await requestToken(props.resourceToken, {
        issuer: 'finaro',
        tokenData: {
          responseCode: tokenizeCardResult.responseCode,
          responseId: tokenizeCardResult.responseId,
          threeDsMethod: tokenizeCardResult.threeDsMethod,
          threeDsTransactionId: tokenizeCardResult.threeDsTransactionId,
          threeDsVersion: tokenizeCardResult.threeDsVersion,
        },
        type: 'token',
        value: tokenizeCardResult.pKey,
      });

      if ('errorCode' in tokenResponse) {
        setErrorMessage(`backend_validation_errors.${tokenResponse.errorCode}`);
        setIsLoading(false);
        return;
      }

      if (tokenResponse.redirectUrl) {
        window.location.href = tokenResponse.redirectUrl;
        return;
      }

      const authorizePaymentResult = await authorizePayment(
        props.authorizeLink,
        props.resourceToken,
        {
          token: tokenResponse.token,
          type: PaymentType.Card,
        },
      );

      if ('errors' in authorizePaymentResult) {
        if (authorizePaymentResult.errors[SupportedBackendErrors.PaymentMethod]) {
          setIsLoading(false);
          setErrorMessage(
            translateBackendErrors(
              authorizePaymentResult.errors[SupportedBackendErrors.PaymentMethod],
              translate,
            ),
          );
        } else {
          throw new Error(
            `Unknown error when validating the data: ${authorizePaymentResult.errors}`,
          );
        }
      } else if (authorizePaymentResult.succeeded) {
        const redirectionLink =
          authorizePaymentResult.links[authorizePaymentResult.workflow.next as string];
        window.location.href = redirectionLink.href;
      } else {
        if (authorizePaymentResult.status === PaymentStatus.Authenticating) {
          const redirectionLink =
            authorizePaymentResult.links[authorizePaymentResult.workflow.next as string];
          window.location.href = redirectionLink.href;
        } else if (authorizePaymentResult.status === PaymentStatus.Declined) {
          setIsLoading(false);
          setErrorMessage('errors.payment_declined');
        } else {
          throw new Error(`Unexpected state of the payment: ${authorizePaymentResult.status}`);
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      props.onUnhandledError(translate('errors.payment_generic'));
    }
  };

  useEffect(() => {
    props.onLoadingChange(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div className="card-payment-container">
      <Stack spacing={2} className="payment-fields">
        {errorMessage && (
          <Alert severity="error" sx={{ marginBottom: '15px' }}>
            {translate(errorMessage)}
          </Alert>
        )}

        <CardField
          id="cardNumber"
          ref={cardNumberRef}
          label={translate('form_labels.card_number')}
          errorMessage={errors.cardNumber}
          disabled={isLoading}
          autoFocus
          required
        />

        <TextField
          id="cardholderName"
          label={translate('form_labels.cardholder_name')}
          inputRef={cardholderNameRef}
          inputProps={{ maxLength: 255 }}
          error={Boolean(errors.cardholderName)}
          helperText={errors.cardholderName}
          disabled={isLoading}
          required
        />

        <Stack spacing={2} direction="row">
          <TextField
            id="expiryDate"
            label={translate('form_labels.expiry_date')}
            value={expiryDate}
            onChange={(e) => handleExpiryDateChange(e.target.value)}
            placeholder={translate('form_labels.expiry_date_placeholder').toString()}
            error={Boolean(errors.expiryDate)}
            helperText={errors.expiryDate}
            disabled={isLoading}
            required
          />

          <TextField
            id="cvv"
            label={translate('form_labels.cvv')}
            value={cvv}
            onChange={(e) => handleCvvChange(e.target.value)}
            placeholder="0000"
            inputProps={{ maxLength: 4 }}
            error={Boolean(errors.cvv)}
            helperText={errors.cvv}
            disabled={isLoading}
            required
          />
        </Stack>
      </Stack>

      <Button
        id="completePaymentBtn"
        className="complete-payment-btn"
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <span style={{ marginRight: 10 }}>{translate('buttons.processing_payment')}</span>
        ) : (
          translate('buttons.complete_payment')
        )}
        {isLoading && <CircularProgress size={17} color="inherit" />}
      </Button>
    </div>
  );
};

export default Card;

interface IProps extends IPaymentMethodPropsBase {
  paymentId: string;
  tokenizationMerchantId: string;
}

interface IErrors {
  cardNumber?: string | null;
  expiryDate?: string | null;
  cvv?: string | null;
  cardholderName?: string | null;
}
